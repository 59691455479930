<template>
    <div>
    
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="itemsWithSno"
        class="elevation-1 mt-10"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
      >
        <template v-slot:top>
          <v-row
            class="px-md-8 pa-6 font-weight-medium title justify-space-between"
          >
            <div>
              Services
            </div>
            <v-spacer></v-spacer>
            <AddEditService :isCreate="true" />
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <AddEditService :service="item" />
            <DeleteDialog @deleteThisItem="deleteThisItem(item)" :id="item._id" />
          </v-row>
        </template>
      </v-data-table>
  
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
  
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  <script>
  import AddEditService from '../components/AddEditService'
  import DeleteDialog from '@/app/base-module/component/DeleteDialog'
  
  export default {
    components: { AddEditService, DeleteDialog },
    apollo: {
      getAllServices() {
        return {
          query: require('../api/getAllServices.graphql'),
          variables: this.variables,
          result({ data, loading }) {
            this.services = data.getAllServices
            this.loading = loading
          }
        }
      }
    },
    data() {
      return {
        snack: false,
        snackColor: '',
        snackText: '',
        pagination: {},
        services: [],
        headers: [
          {
            text: '#',
            sortable: false,
            value: 'slNo',
            width: 120
          },
          { text: 'title English', value: 'titleEn', width: 150 },
          { text: 'title Arabic', value: 'titleAr', width: 150 },
          { text: 'Service Type', value: 'serviceType.titleEn', width: 150 },
          { text: '', value: 'actions', sortable: false, width: 70 }
        ],
        loading: false
      }
    },
    computed: {
      itemsWithSno() {
        return this.services.map((d, index) => ({ ...d, slNo: index + 1 }))
      }
    },
    methods: {
      deleteThisItem(app) {
        this.$apollo
          .mutate({
            mutation: require('../api/deleteService.graphql'),
            variables: {
              id: app._id
            }
          })
          .then(() => {
            location.reload()
          })
          .catch(error => {})
      }
    }
  }
  </script>
  