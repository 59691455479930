<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        dark
        class="mx-2"
        small
        v-on="on"
        v-if="isCreate"
      >
        <v-icon v-bind="attrs">mdi-plus</v-icon>
      </v-btn>
      <v-icon v-bind="attrs" v-on="on" v-else color="primary" class="mx-1"
        >mdi-pencil</v-icon
      >
    </template>
    <v-card>
      <v-card-title>
        <span class="title p-6 ps-2">
          {{ isCreate ? 'Create Statistics' : 'Edit Statistics' }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <ValidationObserver ref="observer">
            <form ref="form">
              <v-row>
                <v-col cols="12" md="6">
                  <!-- contact person info -->

                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Title En"
                      v-model="thisApp.titleEn"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                  <v-text-field
                    dense
                    label="Title Ar"
                    v-model="thisApp.titleAr"
                    type="String"
                    :error-messages="errors"
                  ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                  <v-text-field
                    dense
                    label="Hour"
                    v-model="thisApp.hour"
                    type="String"
                    :error-messages="errors"
                  ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                  <v-text-field
                    dense
                    label="UnitEn"
                    v-model="thisApp.unitEn"
                    type="String"
                    :error-messages="errors"
                  ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                  <v-text-field
                    dense
                    label="UnitAr"
                    v-model="thisApp.unitAr"
                    type="String"
                    :error-messages="errors"
                  ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <p class="green--text text--darken-2">Please Upload png image </p>
                <v-col cols="12" md="12">
                  <img height="100" width="100" :src="newImage" />

                  <v-card
                    @click="onClickImageUpload()"
                    flat
                    height="100px"
                    class="
                  rounded-lg
                  card-custom-border
                  py-10
                  d-flex
                  justify-center
                  align-center
                "
                  >
                    <div>
                    
                      <v-file-input
                        v-model="imageUpload"
                        v-show="false"
                        @change="onImageChange"
                        accept="image/png"
                        ref="imageUpload"
                        label="File input"                       
                       
                      >
                        <template #label>
                          <span class="red--text"><strong>* </strong></span>File
                          input
                        </template></v-file-input>
                       
                      
                      <!-- <v-img
                      class="mx-auto"
                      :src="require('@/core/public/uploads/cameraUpload.png')"
                      width="50px"
                    ></v-img> -->
                      <p class="mt-5">
                        <span class="red--text"><strong>* </strong></span>logo
                      </p>
                      <div class="d-flex flex-column" style="gap: 5px;">
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-2 px-2"
                          height="30px"
                          elevation="0"
                          width="100px"
                          >add logo
                        </v-btn>
                      </div>
                    </div>
                    <p class="red--text text--darken-2">{{ errorMsg }}</p>
                  </v-card>

                 
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <p class="error--text px-4" v-if="errorFromApi">
        {{ errorFromApi }}
      </p>

      <v-card-actions class="pb-6 px-6">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" small text @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          color="primary"
          rounded
          small
          depressed
          :loading="btn_loading"
          class="mx-2 px-2"
          @click="createOrUpdateStatistics"
        >
          {{ isCreate ? 'Create Statistics' : 'Edit Statistics' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateToYYYYMMDD } from '@/app/base-module/util/formatDate'
import '@/app/base-module/util/validation_rules'
export default {
  mounted() {
    formatDateToYYYYMMDD()
  },

  methods: {
    formatDateToYYYYMMDD,
    closeDialog() {
      this.dialog = false
    },
    onImageChange() {     
     
     this.newImage = URL.createObjectURL(this.imageUpload)
   },
    createOrUpdateStatistics() {
      if (this.isCreate) this.createApp()
      else this.updateApp()
    },
    async createApp() {
      if(this.imageUpload==null || this.imageUpload==undefined){
        this.errorMsg ='Image is required';
        return false;
      }
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true

        this.$apollo
          .mutate({
            mutation: require('../api/createStatistics.graphql'),
            variables: {
              titleEn: this.thisApp.titleEn,
              titleAr: this.thisApp.titleAr,
              hour: this.thisApp.hour,
              unitEn:this.thisApp.unitEn,
              unitAr:this.thisApp.unitAr,
              logo: this.imageUpload
            }
          })
          .then(() => {
            this.btn_loading = false
            this.closeDialog()
            location.reload()
          })
          .catch(error => {
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    },
    async updateApp() {     
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        this.$apollo
          .mutate({
            mutation: require('../api/updateStatistics.graphql'),
            variables: {
              titleEn: this.thisApp.titleEn,
              titleAr: this.thisApp.titleAr,
              hour: this.thisApp.hour,
              unitEn:this.thisApp.unitEn,
              unitAr:this.thisApp.unitAr,
              id: this.thisApp.id,
              logo: this.imageUpload
            }
          })
          .then(() => {
            this.btn_loading = false
            this.closeDialog()
            location.reload()
          })
          .catch(error => {
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    },
    onClickImageUpload() {
      this.$refs.imageUpload.$refs.input.click()
    }
  },
  data: () => ({
    thisApp: {},   
    dialog: false,
    errorFromApi: null,
    btn_loading: false,
    imageUpload: null,
    images: [],
    newImage: null,
    errorMsg:'',
  }),
  created() {
    if (!!this.application) {
      let appData = {
        id: !!this.application._id ? this.application._id : '',
        titleEn: !!this.application ? this.application.titleEn : '',
        titleAr: !!this.application ? this.application.titleAr : '',
        hour: !!this.application ? this.application.hour : '',
        unitEn: !!this.application ? this.application.unitEn : '',
        unitAr: !!this.application ? this.application.unitAr : '',
        logo: !!this.application ? this.application.logo : ''
      }

      this.thisApp = appData
this.newImage = `https://excellence-saudi.com:8085/uploads/${this.thisApp.logo}`;
    } else {
      this.thisApp = {}
    }
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style></style>
