
<template>
    <div>
        <Service />
    </div>
</template>
  
<script>
import Service from '../components/Service.vue'
export default {
    components: {
        Service
    }
}
</script>
  
<style lang="scss" scoped></style>
  