
<template>
    <div>
        <ServiceType />
    </div>
</template>
  
<script>
import ServiceType from '../components/ServiceType.vue'
export default {
    components: {
        ServiceType
    }
}
</script>
  
<style lang="scss" scoped></style>
  