
<template>
    <div>
        <Client />
    </div>
</template>
  
<script>
import Client from '../components/Client.vue'
export default {
    components: {
        Client
    }
}
</script>
  
<style lang="scss" scoped></style>
  