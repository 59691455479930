<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed rounded color="primary" dark class="mx-2" small v-on="on" v-if="isCreate">
        <v-icon v-bind="attrs">mdi-plus</v-icon>
      </v-btn>
      <v-icon v-bind="attrs" v-on="on" v-else color="primary" class="mx-1">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="title p-6 ps-2">
          {{ isCreate ? 'Create service' : 'Edit service' }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <ValidationObserver ref="observer">
            <form ref="form">
              <v-row>
                <v-col cols="12" md="6">
                  <!-- contact person info -->

                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Service Title in English"
                      v-model="thisApp.titleEn"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Service Title in Arabic"
                      v-model="thisApp.titleAr"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">                  
                   
                    <!-- <v-select
          :items="serviceTypeList"
          label="Service Type"
          v-model="thisApp.serviceType"
          :error-messages="errors"
        ></v-select> -->
       
        <v-select v-model="thisApp.serviceType"   :items="serviceTypeList" solo
          item-text="titleEn" item-value="_id" label="Service Type" dense :error-messages="errors"></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
               
               <p class="green--text text--darken-2"> Please keep the points short and limit them to two lines 
                for better readability in the application</p>
              </v-row>
              <v-row>
                <v-col cols="6" md="6">
                  <v-row v-for="item in servicesEn" :key="item.idx">
                    <v-textarea
                      dense
                      label="Service Details in English"
                      v-model="item.text"
                    ></v-textarea>

                    <v-btn
                      depressed
                      rounded
                      v-if="servicesEn.length > 1 ? true : false"
                      color="primary"
                      dark
                      class="mx-2"
                      small
                      @click="removeService(item)"
                    >
                      <v-icon v-bind="attrs">mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                  <v-btn
                    depressed
                    rounded
                    color="primary"
                    dark
                    class="mx-2"
                    small
                    @click="addService"
                  >
                  Add Service details(En)
                  </v-btn>
                </v-col>
                <!-- service details arabic -->
                <v-col cols="6">
                  <v-row v-for="item in servicesAr" :key="item.idx">
                    <v-textarea
                      dense
                      label="Service Details in Arabic"
                      v-model="item.text"
                    ></v-textarea>

                    <v-btn
                      depressed
                      rounded
                      v-if="servicesAr.length > 1 ? true : false"
                      color="primary"
                      dark
                      class="mx-2"
                      small
                      @click="removeArabicService(item)"
                    >
                      <v-icon v-bind="attrs">mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                  <v-btn
                    depressed
                    rounded
                    color="primary"
                    dark
                    class="mx-2"
                    small
                    @click="addArabicService"
                  >
                  Add Service details (Ar)
                  
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <p class="error--text px-4" v-if="errorFromApi">
        {{ errorFromApi }}
      </p>

      <v-card-actions class="pb-6 px-6">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" small text @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          color="primary"
          rounded
          small
          depressed
          :loading="btn_loading"
          class="mx-2 px-2"
          @click="createOrUpdateservice"
        >
          {{ isCreate ? 'Create service' : 'Edit service' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateToYYYYMMDD } from '@/app/base-module/util/formatDate'
import '@/app/base-module/util/validation_rules'
export default {
  mounted() {
    formatDateToYYYYMMDD()
    
  },

  methods: {   
    addService() {
      this.index++
      this.servicesEn.push({ text: '', idx: this.index })
    },
    removeService(item) {
      this.servicesEn = this.servicesEn.filter(x => x.idx != item.idx)
    },
    addArabicService() {
      this.index_ar++
      this.servicesAr.push({ text: '', idx: this.index_ar })
    },
    removeArabicService(item) {
      this.servicesAr = this.servicesAr.filter(x => x.idx != item.idx)
    },
    formatDateToYYYYMMDD,
    closeDialog() {
      this.dialog = false
    },

    createOrUpdateservice() {
      if (this.isCreate) this.createApp()
      else this.updateApp()
    },
    async createApp() {
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
debugger
        this.$apollo
          .mutate({
            mutation: require('../api/createService.graphql'),
            variables: {
              titleEn: this.thisApp.titleEn,
              titleAr: this.thisApp.titleAr,
              serviceType: this.thisApp.serviceType,
              serviceDetailsEn: this.servicesEn.map(x => x.text),
              serviceDetailsAr: this.servicesAr.map(x => x.text)
            }
          })
          .then(() => {
            this.btn_loading = false
            this.closeDialog()
            location.reload()
          })
          .catch(error => {
            console.log(error)
            alert(error);
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    },
    async updateApp() {
  
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        this.$apollo
          .mutate({
            mutation: require('../api/updateService.graphql'),
            variables: {
              id: this.thisApp.id,
              titleEn: this.thisApp.titleEn,
              titleAr: this.thisApp.titleAr,
              serviceType: this.thisApp.serviceType._id,
              serviceDetailsEn: this.servicesEn.map(x => x.text),
              serviceDetailsAr: this.servicesAr.map(x => x.text)
            }
          })
          .then(() => {
            this.btn_loading = false
            this.closeDialog()
            location.reload()
          })
          .catch(error => {
            console.log(error)
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    }
  },
  data: () => ({
    thisApp: {},
    servicesEn: [],
    servicesAr: [],
    dialog: false,
    errorFromApi: null,
    btn_loading: false,
    index: 0,
    index_ar: 0,
   // serviceTypeList: ['Excellence Technology', 'Excellence Dimensions'],
    serviceTypeList: []

  }),
  apollo: {
      getAllServiceType() {
        debugger
        return {
          query: require('../../service-type/api/getAllServiceType.graphql'),
          variables: this.variables,
          result({ data, loading }) {
            debugger
           this.serviceTypeList = data.getAllServiceType;
            this.loading = loading
          }
        }
      }
    },
  created() {
    if (this.service) {
      let appData = {
        id: !!this.service._id ? this.service._id : '',
        titleEn: !!this.service ? this.service.titleEn : '',
        titleAr: !!this.service ? this.service.titleAr : '',
        serviceType: !!this.service ? this.service.serviceType : ''
      }

      this.servicesEn = new Array()

      this.service.serviceDetailsEn.forEach(element => {
        this.servicesEn.push({ text: element, idx: this.index })
        this.index++
      })

      this.servicesAr = new Array()

      this.service.serviceDetailsAr.forEach(element => {
        this.servicesAr.push({ text: element, idx: this.index_ar })
        this.index_ar++
      })

      this.thisApp = appData
    } else {
      this.thisApp = {}
    }
  },
  props: {
    service: {
      type: Object,
      required: true,
      default: () => {}
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style></style>
