var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('ValidationObserver',{ref:"observer"},[_c('form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Facebook ","error-messages":errors},model:{value:(_vm.socialMedia.faceBook),callback:function ($$v) {_vm.$set(_vm.socialMedia, "faceBook", $$v)},expression:"socialMedia.faceBook"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Instagram","error-messages":errors},model:{value:(_vm.socialMedia.instagram),callback:function ($$v) {_vm.$set(_vm.socialMedia, "instagram", $$v)},expression:"socialMedia.instagram"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Twitter","error-messages":errors},model:{value:(_vm.socialMedia.twitter),callback:function ($$v) {_vm.$set(_vm.socialMedia, "twitter", $$v)},expression:"socialMedia.twitter"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Linkdin ","error-messages":errors},model:{value:(_vm.socialMedia.linkdin),callback:function ($$v) {_vm.$set(_vm.socialMedia, "linkdin", $$v)},expression:"socialMedia.linkdin"}})]}}])})],1)],1),_c('v-row',[_c('v-btn',{staticClass:"mx-2 px-2",attrs:{"color":"primary","rounded":"","small":"","depressed":"","loading":_vm.btn_loading},on:{"click":_vm.updateApp}},[_vm._v(" Update SocialMedia ")]),(this.successMsg!='')?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.successMsg)+" ")]):_vm._e()],1),_c('v-row')],1)])],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }