<template>
  <div>
    <v-container>
      <ValidationObserver ref="observer">
        <form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <!-- contact person info -->

              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  dense
                  type="password"
                  label="Current Password "
                  v-model="currentPassword"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <!-- contact person info -->

              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  dense
                  type="password"
                  label="New PassWord "
                  v-model="newPassword"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-btn
              color="primary"
              rounded
              small
              depressed
              :loading="btn_loading"
              class="mx-2 px-2"
              @click="updateApp"
            >
              Update Password
            </v-btn>
            <v-alert v-if="this.successMsg != ''" type="success">
              {{ successMsg }}
            </v-alert>
            <v-alert v-if="this.errorMsg != ''" type="Error">
              {{ errorMsg }}
            </v-alert>
          </v-row>
        </form>
      </ValidationObserver>
    </v-container>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import '@/app/base-module/util/validation_rules'
export default {
  components: {},
  apollo: {},
  data() {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      loading: false,
      btn_loading: false,
      successMsg: '',
      currentPassword: '',
      newPassword: '',
      errorMsg: ''
    }
  },
  computed: {
    itemsWithSno() {
      return ['1']
    }
  },
  methods: {
    async updateApp() {
      debugger
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        this.$apollo
          .mutate({
            mutation: require('../api/updatePassword.graphql'),
            variables: {
              currentPassword: this.currentPassword,
              newPassword: this.newPassword
            }
          })
          .then((response) => {
       
            this.btn_loading = false

            // location.reload()
            this.currentPassword = ''
            this.newPassword = ''
            this.successMsg = 'Updated Successfuly'
            console.log(this.successMsg)
            setTimeout(() => {
              //location.reload()
              this.successMsg = ''
            }, 5000)
          })
          .catch(error => {
            this.btn_loading = false
            if (error.graphQLErrors != null) {
              this.errorMsg = JSON.stringify(error.graphQLErrors[0].message)
            setTimeout(() => {
              //location.reload()
              this.errorMsg = ''
            }, 5000)
            this.errorFromApi = response
            this.btn_loading = false
            
             
          }
          
           
          })
      }
    }
  }
}
</script>
