const formatDate = date => {
  if (date == null) return null
  return new Date(parseInt(date)).toLocaleString('en-US', {
    timeZone: 'Asia/Kuwait',
    year: 'numeric',
    month: '2-digit',
    day: 'numeric'
  })
}
const formatDateToYYYYMMDD = date => {
  if (date == null) return null
  return new Date(parseInt(date)).toISOString().substr(0, 10)
}
export { formatDate, formatDateToYYYYMMDD }
