<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-10"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:top>
        <v-row
          class="px-md-8 pa-6 font-weight-medium title justify-space-between"
        >
          <div>
            Statistics
          </div>
          <v-spacer></v-spacer>
          <AddEditApplication :isCreate="true" v-if="statistics.length < 5" />
        </v-row>
      </template>
      <template v-slot:item.logo="{ item }">
          <!-- <img width="50" :src="`https://excellence-saudi.com:8085/uploads/${item.logo}`"> -->
          <img width="50" :src="`https://excellence-saudi.com:8085/uploads/${item.logo}`">
          
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <AddEditApplication :application="item" />
          <DeleteDialog @deleteThisItem="deleteThisItem(item)" :id="item._id" />
        </v-row>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import AddEditApplication from '../components/AddEditApplication'
import DeleteDialog from '@/app/base-module/component/DeleteDialog'

export default {
  components: { AddEditApplication, DeleteDialog },
  apollo: {
    getAllStatistics() {
      return {
        query: require('../api/getAllStatistics.graphql'),
        variables: this.variables,
        result({ data, loading }) {
          this.statistics = data.getAllStatistics
          this.loading = loading
        }
      }
    }
  },
  data() {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      pagination: {},
      statistics: [],
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'slNo',
          width: 120
        },
        { text: 'Title in English', value: 'titleEn', width: 150 },
        { text: 'Title in  Arabic', value: 'titleAr', width: 150 },
        { text: 'Hour', value: 'hour', width: 150 },
        { text: 'Unit in English', value: 'unitEn', width: 150 },
        { text: 'Unit in Arabic', value: 'unitAr', width: 150 },
        { text: 'logo', value: 'logo', width: 150 },
        { text: '', value: 'actions', sortable: false, width: 70 }
      ],
      loading: false
    }
  },
  computed: {
    itemsWithSno() {
      return this.statistics.map((d, index) => ({ ...d, slNo: index + 1 }))
    }
  },
  methods: {
    deleteThisItem(app) {
      this.$apollo
        .mutate({
          mutation: require('../api/deleteStatistics.graphql'),
          variables: {
            id: app._id
          }
        })
        .then(() => {
          location.reload()
        })
        .catch(error => {})
    }
  }
}
</script>
