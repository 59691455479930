<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="450">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="error" class="mx-1"
          >mdi-delete</v-icon
        >
      </template>
      <v-card>
        <v-card-title class="font-weight-bold">{{
          $t('general.deleteQestion')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            class="font-weight-bold"
            text
            @click="cancel()"
            >{{ $t('general.no') }}</v-btn
          >
          <v-btn
            color="error"
            class="font-weight-bold"
            text
            @click="deleteItem()"
            >{{ $t('general.deleteAnswer') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  props: {
    id: {
      required: true
    }
  },
  methods: {
    show() {
      this.dialog = true
    },
    deleteItem() {
      this.$emit('deleteThisItem', true, this.id);
      this.dialog = false
    },
    cancel() {
      this.$emit('deleteThisItem', false, this.id);
      this.dialog = false
    }
  }
}
</script>
