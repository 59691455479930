<template>
  <div>
    <v-container>
      <ValidationObserver ref="observer">
        <form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <!-- contact person info -->

              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  dense
                  label="whatsapp "
                  v-model="whatsapp.whatsApp"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-btn
              color="primary"
              rounded
              small
              depressed
              :loading="btn_loading"
              class="mx-2 px-2"
              @click="updateApp"
            >
              Update WhatsApp
            </v-btn>
            <v-alert v-if="this.successMsg!=''"
  type="success"
>
{{ successMsg }}
</v-alert>
          </v-row>
        </form>
      </ValidationObserver>
    </v-container>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import '@/app/base-module/util/validation_rules'
export default {
  components: {},
  apollo: {
    getWhatsApp() {
      return {
        query: require('../api/getWhatsApp.graphql'),
        variables: this.variables,
        result({ data, loading }) {
          this.whatsapp = data.getWhatsApp
          this.loading = loading
        }
      }
    }
  },
  data() {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      whatsapp: null,
      loading: false,
      btn_loading: false,
      successMsg:''
    }
  },
  computed: {
    itemsWithSno() {
      return ['1']
    }
  },
  methods: {
    async updateApp() {
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        this.$apollo
          .mutate({
            mutation: require('../api/updateWhatsApp.graphql'),
            variables: {
              id: this.whatsapp._id,
              whatsApp: this.whatsapp.whatsApp
            }
          })
          .then(() => {
            this.btn_loading = false
           
           // location.reload()
           this.successMsg ="Updated Successfuly"
            console.log(this.successMsg)
            setTimeout(() => {
              //location.reload()
            this.successMsg = ''
          }, 5000)
          })
          .catch(error => {
            console.log(error)
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    }
  }
}
</script>
