<template>
  
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        dark
        class="mx-2"
        small
        v-on="on"
        v-if="isCreate"
      >
        <v-icon v-bind="attrs">mdi-plus</v-icon>
      </v-btn>
      <v-icon v-bind="attrs" v-on="on" v-else color="primary" class="mx-1"
        >mdi-pencil</v-icon
      >
    </template>
    <v-card>
      <v-card-title>
        <span class="title p-6 ps-2">
          {{ isCreate ? 'Create Service Type' : 'Edit Service Type' }}
        </span>
        
      </v-card-title>
      <v-card-text>
        <v-container>
          <ValidationObserver ref="observer">
            <form ref="form">
              <v-row>
                <v-col cols="12" md="6">
                  <!-- contact person info -->

                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Title Name in English"
                      v-model="thisApp.titleEn"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Title Name in Arabic"
                      v-model="thisApp.titleAr"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Sub Text in English"
                      v-model="thisApp.subTextEn"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      dense
                      label="Sub Text in Arabic"
                      v-model="thisApp.subTextAr"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12">
                 
                  <img height="100" width="100" :src="newImage" />

                  <v-card
                    @click="onClickImageUpload()"
                    flat
                    height="100px"
                    class="
                  rounded-lg
                  card-custom-border
                  py-10
                  d-flex
                  justify-center
                  align-center
                "
                  >
                    <div>
                      <v-file-input
                        v-model="imageUpload"
                        v-show="false"
                        @change="onImageChange"
                        accept="image/*"
                        ref="imageUpload"
                        label="File input"
                      >
                        <template #label>
                          <span class="red--text"><strong>* </strong></span>File
                          input
                        </template></v-file-input
                      >
                     
                      <p class="mt-5">
                        <span class="red--text"><strong>* </strong></span>logo
                      </p>
                      <div class="d-flex flex-column" style="gap: 5px;">
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-2 px-2"
                          height="30px"
                          elevation="0"
                          width="100px"
                          > {{ isCreate ? 'Add' : 'chanage' }}
                        </v-btn>
                      </div>
                    </div>
                    <p class="red--text text--darken-2">{{ errorMsg }}</p>
                  </v-card>

                 
                </v-col>
               
              </v-row>
              <v-row> </v-row>
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <p class="error--text px-4" v-if="errorFromApi">
        {{ errorFromApi }}
      </p>

      <v-card-actions class="pb-6 px-6">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" small text @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          color="primary"
          rounded
          small
          depressed
          :loading="btn_loading"
          class="mx-2 px-2"
          @click="createOrUpdateServiceType"
        >
          {{ isCreate ? 'Create Service Type' : 'Edit Service Type' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateToYYYYMMDD } from '@/app/base-module/util/formatDate'
import '@/app/base-module/util/validation_rules'
export default {
  mounted() {
    formatDateToYYYYMMDD()
  },

  // watch: {
  //   imageUpload: {
  //     handler(images) {
  //       if (images && images.length) {
  //         images.map(val => {
  //           let noOfImages = this.images.length
  //           this.images[noOfImages] = URL.createObjectURL(val)
  //         })
  //       }
  //     }
  //   }
  // },

  methods: {
    formatDateToYYYYMMDD,
    closeDialog() {
      this.dialog = false
    },

    onImageChange() {    
      this.newImage = URL.createObjectURL(this.imageUpload)
    },
    createOrUpdateServiceType() {
    
      if (this.isCreate) this.createApp()
      else this.updateApp()
    },
    async createApp() {
      if(this.imageUpload==null || this.imageUpload==undefined){
        this.errorMsg ='Image is required';
        return false;
      }
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true

        this.$apollo
          .mutate({
            mutation: require('../api/createServiceType.graphql'),
            variables: {
              titleEn: this.thisApp.titleEn,
              titleAr: this.thisApp.titleAr,
              logo: this.imageUpload,
              subTextEn:this.thisApp.subTextEn,
              subTextAr:this.thisApp.subTextAr,
            }
          })
          .then(() => {
            this.btn_loading = false
            this.closeDialog()
            location.reload()
          })
          .catch(error => {
            console.log(error)
            alert(error);
            alert(response);
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    },
    async updateApp() {
    console.log(this.thisApp);
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        this.$apollo
          .mutate({
            mutation: require('../api/updateServiceType.graphql'),
            variables: {
              id: this.thisApp.id,
              titleEn: this.thisApp.titleEn,
              titleAr: this.thisApp.titleAr,
              logo: this.imageUpload,
              subTextEn:this.thisApp.subTextEn,
              subTextAr:this.thisApp.subTextAr,
            }
          })
          .then(() => {
            this.btn_loading = false
            this.closeDialog()
            location.reload()
          })
          .catch(error => {
            console.log(error)
            this.errorFromApi = response
            this.btn_loading = false
          })
      }
    },
    onClickImageUpload() {
      this.$refs.imageUpload.$refs.input.click()
    }
  },
  data: () => ({
    thisApp: {},
    dialog: false,
    errorFromApi: null,
    btn_loading: false,
    imageUpload: null,
    images: [],
    newImage: null,
    errorMsg:''
  }),
  created() {
    if (this.serviceType) {     
      let appData = {
        id: !!this.serviceType._id ? this.serviceType._id : '',
        titleEn: !!this.serviceType ? this.serviceType.titleEn : '',
        titleAr: !!this.serviceType ? this.serviceType.titleAr : '',
        logo: !!this.serviceType ? this.serviceType.logo : '',
        subTextEn: !!this.serviceType ? this.serviceType.subTextEn : '',
        subTextAr: !!this.serviceType ? this.serviceType.subTextAr : '',
      }
     

      this.thisApp = appData
      this.newImage = `https://excellence-saudi.com:8085/uploads/${this.thisApp.logo}`;
    } else {
      this.thisApp = {}
    }
  },
  props: {
    serviceType: {
      type: Object,
      required: true,
      default: () => {}
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style></style>
