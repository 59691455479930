
<template>
    <div>
        <SocialMedia />
    </div>
</template>
  
<script>
import SocialMedia from '../components/SocialMedia.vue'
export default {
    components: {
        SocialMedia
    }
}
</script>
  
<style lang="scss" scoped></style>
  